<template>
<main class="manager">
  <Html>
    <Head>
      <Title>i-Scream arts / Manager</Title>
    </Head>
  </Html>
  <header class="header">
    <h1 class="header__title">
      <nuxt-link to="/manager/">
        <strong>i-Scream arts</strong>
        <span>Manager</span>
      </nuxt-link>
    </h1>
    <nav class="header__navigation">
      <nuxt-link to="/manager/press/">
        <span>Press</span>
      </nuxt-link>
      <nuxt-link to="/manager/recruit/">
        <span>Recruit</span>
      </nuxt-link>
      <nuxt-link to="/manager/faq/">
        <span>FAQ</span>
      </nuxt-link>
      <nuxt-link to="/manager/recruit-letter/">
        <span>Recruit Letter</span>
      </nuxt-link>
    </nav>
    <nav v-if="auth.token" class="header__control">
      <button
        type="button"
        title="서비스로 이동하기"
        @click="onGotoService">
        <Icon icon-name="home"/>
      </button>
      <button
        type="button"
        title="로그아웃"
        @click="onLogout">
        <Icon icon-name="logout"/>
      </button>
    </nav>
  </header>
  <div class="container">
    <div class="container__wrap">
      <slot/>
    </div>
  </div>
</main>
</template>

<script setup>
import { useAuthStore } from '~/store/auth'
import Icon from '~/components/icon/index.vue'

const runtime = useRuntimeConfig()
const auth = useAuthStore()
const router = useRouter()

async function onLogout()
{
  if (!confirm('정말 로그아웃 할까요?')) return
  const { data } = await useFetch('/api/logout', {
    method: 'post',
    body: {},
  })
  if (!data.value.success) throw new Error('Failed logout')
  auth.token = undefined
  await router.replace('/manager/login/')
}

function onGotoService()
{
  if (!confirm('홈페이지로 이동할까요?')) return
  location.href = runtime.app.baseURL
}
</script>

<style src="./manager.scss" lang="scss"></style>
<style src="./manager.scoped.scss" lang="scss" scoped></style>
